import { fromFetch } from "rxjs/fetch";
import { catchError, of, switchMap } from "rxjs";
import { authStore } from "../authStore";
import qs from "qs";
import { getRawAllItemsWrapper } from "./get-all-pages-items";

export async function getStartups() {
  try {
    const req = await fetch(
      `${process.env.API_URL}/api/users/startups?limit=0`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authStore.token.value}`,
        },
      }
    ).then((res) => res.json());
    //console.log('startups ->', req.docs)
    return req.docs;
  } catch (err) {
    console.log(err);
  }
}

export function getRawStartups() {
  let url = `${process.env.API_URL}/api/users`;
  const urlOptions = { sort: "updatedAt" };

  let activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  urlOptions.limit = 400;
  urlOptions.where = {
    //updatedAt: { greater_than_equal: minTimestamp },
    "programmes.programme": {
      equals: authStore.programmeId.value,
    },
    "programmes.cohorts": {
      in: activeCohortsIds,
    },
    role: {
      equals: "startup",
    },
    _deleted: { not_equals: true },
  };
  const queryString = qs.stringify(urlOptions, { addQueryPrefix: true });
  const fullUrl = `${url}${queryString}`; // url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
  return fromFetch(fullUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  }).pipe(
    switchMap((response) => {
      if (response.ok) {
        // OK return data
        return response.json();
      } else {
        // Server is returning a status requiring the client to try something else.
        return of({ error: true, message: `Error ${response.status}` });
      }
    }),
    catchError((err) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({ error: true, message: err.message });
    })
  );
}

export function getRawMentors() {
  let url = `${process.env.API_URL}/api/users`;
  const urlOptions = { sort: "updatedAt" };

  let activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  urlOptions.limit = 300;
  urlOptions.where = {
    //updatedAt: { greater_than_equal: minTimestamp },
    "programmes.programme": {
      equals: authStore.programmeId.value,
    },
    "programmes.cohorts": {
      in: activeCohortsIds,
    },
    role: {
      equals: "mentor",
    },
  };
  const queryString = qs.stringify(urlOptions, { addQueryPrefix: true });
  const fullUrl = `${url}${queryString}`; // url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
  return fromFetch(fullUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  }).pipe(
    switchMap((response) => {
      if (response.ok) {
        // OK return data
        return response.json();
      } else {
        // Server is returning a status requiring the client to try something else.
        return of({ error: true, message: `Error ${response.status}` });
      }
    }),
    catchError((err) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({ error: true, message: err.message });
    })
  );
}

// to get all the items with small limit
export function getRawAllStartups() {
  let url = `${process.env.API_URL}/api/users`;
  const urlOptions = { sort: "updatedAt" };

  let activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  urlOptions.where = {
    "programmes.programme": {
      equals: authStore.programmeId.value,
    },
    "programmes.cohorts": {
      in: activeCohortsIds,
    },
    role: {
      equals: "startup",
    },
    _deleted: { not_equals: true },
  };

  // need to pass url, urlOptions, limit
  return getRawAllItemsWrapper(url, urlOptions, 100);
}

/*
to use it in the lit Tasks:
task: async ([], { signal }) => {
  return new Promise(function (resolve, reject) {
    getRawAllStartups().subscribe((result) => {
      if (result && result.length > 0) {
        resolve(result);
      }
    });
  });
};
*/

export function getRawMinimizedStartupsByPage(page) {
  let url = `${process.env.API_URL}/api/users`;
  const urlOptions = {};

  let activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  urlOptions.limit = 20;
  urlOptions.page = page;
  urlOptions.sort = "updatedAt";
  urlOptions.isBulkMinimiseResponse = true;
  urlOptions.depth = 2;

  urlOptions.where = {
    //updatedAt: { greater_than_equal: minTimestamp },
    "programmes.programme": {
      equals: authStore.programmeId.value,
    },
    "programmes.cohorts": {
      in: activeCohortsIds,
    },
    role: {
      equals: "startup",
    },
    _deleted: { not_equals: true },
  };
  const queryString = qs.stringify(urlOptions, { addQueryPrefix: true });
  const fullUrl = `${url}${queryString}`; // url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
  return fromFetch(fullUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  }).pipe(
    switchMap((response) => {
      if (response.ok) {
        // OK return data
        return response.json();

        // const result = await response.json();
        // let docs = [...result.docs].map(item => minimiseUserObj(item))
        //return { ...result, docs: docs };
      } else {
        // Server is returning a status requiring the client to try something else.
        return of({ error: true, message: `Error ${response.status}` });
      }
    }),
    catchError((err) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({ error: true, message: err.message });
    })
  );
}

export function getRawMinimizedStartups(xWhere = {}, limit = 400) {
  let url = `${process.env.API_URL}/api/users`;
  const urlOptions = {};

  let activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  urlOptions.limit = limit;
  urlOptions.sort = "updatedAt";
  urlOptions.isBulkMinimiseResponse = true;
  urlOptions.depth = 2;

  urlOptions.where = {
    ...xWhere,
    "programmes.programme": {
      equals: authStore.programmeId.value,
    },
    "programmes.cohorts": {
      in: activeCohortsIds,
    },
    role: {
      equals: "startup",
    },
    _deleted: { not_equals: true },
  };
  const queryString = qs.stringify(urlOptions, { addQueryPrefix: true });
  const fullUrl = `${url}${queryString}`; // url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
  return fromFetch(fullUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  }).pipe(
    switchMap((response) => {
      if (response.ok) {
        return response.json();
      } else {
        // Server is returning a status requiring the client to try something else.
        return of({ error: true, message: `Error ${response.status}` });
      }
    }),
    catchError((err) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({ error: true, message: err.message });
    })
  );
}

export function getRawStartupByFounderId(founderId) {
  const url = `${process.env.API_URL}/api/users`;
  const activeCohortsIds = authStore.cohort.value
    .map((item) => item.id)
    .join(",");

  const urlOptions = {
    limit: 1,
    where: {
      founders: { in: founderId },
      "programmes.programme": { equals: authStore.programmeId.value },
      "programmes.cohorts": { in: activeCohortsIds },
      role: { equals: "startup" },
      _deleted: { not_equals: true },
    },
  };

  const queryString = qs.stringify(urlOptions, { addQueryPrefix: true });
  const fullUrl = `${url}${queryString}`;
  return fromFetch(fullUrl, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${authStore.token.value}`,
    },
  }).pipe(
    switchMap((response) => {
      if (response.ok) {
        // OK return data
        return response.json();
      } else {
        // Server is returning a status requiring the client to try something else.
        return of({ error: true, message: `Error ${response.status}` });
      }
    }),
    catchError((err) => {
      // Network or other error, handle appropriately
      console.error(err);
      return of({ error: true, message: err.message });
    })
  );
}
