export const startupsSchema = {
  title: "Startups Schema",
  version: 0,
  description: "Describes a Startup",
  primaryKey: "id",
  type: "object",

  properties: {
    id: {
      type: "string",
      maxLength: 100,
    },
    email: {
      type: "string",
    },
    founders: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          _deleted: {
            type: "boolean",
          },
          educations: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                school: {
                  type: "string",
                },
                degree: {
                  type: "string",
                },
                fieldOfStudy: {
                  type: "string",
                },
                start: {
                  type: "string",
                },
                end: {
                  type: "string",
                },
                grade: {
                  type: "string",
                },
              },
            },
          },
          experiences: {
            type: "array",
            items: {
              type: "object",
              properties: {
                title: {
                  type: "string",
                },
                company: {
                  type: "string",
                },
                start: {
                  type: "string",
                },
                end: {
                  type: "string",
                },
                location: {
                  type: "string",
                },
                id: {
                  type: "string",
                },
              },
            },
          },
          socials: {
            type: "array",
            items: {
              type: "object",
              properties: {
                platform: {
                  type: "string",
                },
                url: {
                  type: "string",
                },
                id: {
                  type: "string",
                },
              },
            },
          },
          occupation: {
            type: "string",
          },
          email: {
            type: "string",
          },
          location: {
            type: "string",
          },
          picture: {
            type: "string",
          },
          skills: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
                type: {
                  type: "string",
                },
                _deleted: {
                  type: "boolean",
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
    role: {
      type: "string",
    },
    roles: {
      type: "array",
      items: {
        type: "string",
      },
    },
    availableMentorMentoringProgrammeMinutes: {
      type: "number",
    },
    mentorMentoringProgrammeMinutes: {
      type: "number",
    },
    mentorMentoringProgrammeTopicHours: {
      type: "array",
      items: {
        type: "number",
      },
    },
    _deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    startup: {
      type: "object",
      properties: {
        logo: {
          type: "string",
        },
        address: {
          type: "string",
        },
        website: {
          type: "string",
        },
        socials: {
          type: "array",
          items: {
            type: "object",
            properties: {
              platform: {
                type: "string",
              },
              url: {
                type: "string",
              },
              id: {
                type: "string",
              },
            },
          },
        },
        foundingYear: {
          type: "string",
        },
        challenge: {
          type: "string",
        },
        teamSize: {
          type: "string",
        },
        industries: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              label: {
                type: "string",
              },
              type: {
                type: "string",
              },
              _deleted: {
                type: "boolean",
              },
            },
          },
        },
      },
    },
    description: {
      type: "string",
    },
    isAdmin: {
      type: "boolean",
    },
    updatedAt: {
      type: "string",
    },
    lockUntil: {
      type: ["string", "null"],
    },
    Export: {
      type: "object",
      items: {},
    },
  },
  required: ["id"],
};
