import { firstValueFrom, Subject } from "rxjs";
import { lastOfArray } from "rxdb";
import { replicateRxCollection } from "rxdb/plugins/replication";
import { getRawMinimizedStartups } from "../../utilities/requests/get-startups";

export function startupsReplicateHandler(db) {
  const myPullStream$ = new Subject();

  addEventSource();

  const startupsReplState = replicateRxCollection({
    collection: db.startups,
    replicationIdentifier: "startupsReplication",
    retryTime: 5 * 500,
    pull: {
      handler: pullHandler,
      batchSize: 200,
      stream$: myPullStream$.asObservable(),
    },
  });

  startupsReplState.error$.subscribe((err) => {
    console.log("Startups Replication Error", err);
  });

  return startupsReplState;

  async function pullHandler(lastCheckpoint, batchSize) {
    console.log("LAST CHECKPOINT STARTUPS", lastCheckpoint);
    const minTimestamp = lastCheckpoint
      ? lastCheckpoint.updatedAt
      : new Date(0).toISOString();
    console.log(minTimestamp, lastCheckpoint);

    const documentsFromRemote = await firstValueFrom(
      getRawMinimizedStartups(
        {
          updatedAt: { greater_than: minTimestamp },
        },
        batchSize
      )
    );
    const { docs } = documentsFromRemote;

    const checkpoint =
      docs.length === 0
        ? lastCheckpoint
        : {
            id: lastOfArray(docs).id,
            updatedAt: lastOfArray(docs).updatedAt,
          };

    console.log("DOCUMENTS FROM REMOTE", docs, checkpoint);

    return {
      documents: docs,
      checkpoint,
    };
  }

  function addEventSource() {
    const url = `${process.env.API_URL}/api/startups-stream`;
    const options = { withCredentials: true };
    const eventSource = new EventSource(url, options);

    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      console.log("STARTUP EVENT DATA", eventData);

      myPullStream$.next({
        documents: eventData.documents,
        checkpoint: eventData.checkpoint,
      });
    };
  }
}
