export const userSchema = {
    title: "User Schema",
    version: 0,
    description: "Describes a User",
    primaryKey: "id",
    type: "object",

    properties: {
      id: {
        type: "string",
        maxLength: 100,
      },
      roles: {
        type: "array",
        items: {
          type: "string",
        },
      },
      email: {
        type: "string",
      },
      createdAt: {
        type: "string",
      },
      updatedAt: {
        type: "string",
      },
      founders: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: {
              type: "string",
            },
            name: {
              type: "string",
            },
            createdAt: {
              type: "string",
            },
            updatedAt: {
              type: "string",
            },
            _deleted: {
              type: "boolean",
            },
            educations: {
              type: "array",
              items: {},
            },
            experiences: {
              type: "array",
              items: {},
            },
            mentoringProgrammeTopicMinutes: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  topic: {
                    type: "string",
                  },
                  minutes: {
                    type: "number",
                  },
                  id: {
                    type: "string",
                  },
                },
              },
            },
            socials: {
              type: "array",
              items: {},
            },
            occupation: {
              type: "string",
            },
            description: {
              type: "string",
            },
          },
        },
      },
      programmes: {
        type: "array",
        items: {
          type: "object",
          properties: {
            programme: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                name: {
                  type: "string",
                },
                domains: {
                  type: "array",
                  items: {},
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
                cohorts: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
                _deleted: {
                  type: "boolean",
                },
                mentoringProgrammeTopicHours: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      topic: {
                        type: "string",
                      },
                      hours: {
                        type: "number",
                      },
                      id: {
                        type: "string",
                      },
                    },
                  },
                },
                mentoringProgrammeTopicMinutes: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      topic: {
                        type: "string",
                      },
                      minutes: {
                        type: "number",
                      },
                      id: {
                        type: "string",
                      },
                    },
                  },
                  start: {
                    type: "string",
                  },
                },
              },
            },
            role: {
              type: "string",
            },
            cohorts: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: {
                    type: "string",
                  },
                  name: {
                    type: "string",
                  },
                  createdAt: {
                    type: "string",
                  },
                  updatedAt: {
                    type: "string",
                  },
                  _deleted: {
                    type: "boolean",
                  },
                },
              },
              image: {
                type: "string",
              },
            },
            id: {
              type: "string",
            },
          },
        },
      },
      lastLoggedInProgramme: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          domains: {
            type: "array",
            items: {},
          },
          createdAt: {
            type: "string",
          },
          updatedAt: {
            type: "string",
          },
          cohorts: {
            type: "array",
            items: {
              type: "string",
            },
          },
          _deleted: {
            type: "boolean",
          },
          mentoringProgrammeTopicHours: {
            type: "array",
            items: {
              type: "object",
              properties: {
                topic: {
                  type: "string",
                },
                hours: {
                  type: "number",
                },
                id: {
                  type: "string",
                },
              },
            },
          },
          mentoringProgrammeTopicMinutes: {
            type: "array",
            items: {
              type: "object",
              properties: {
                topic: {
                  type: "string",
                },
                minutes: {
                  type: "number",
                },
                id: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      role: {
        type: "string",
      },
      _deleted: {
        type: "boolean",
      },
      name: {
        type: "string",
      },
      mentor: {
        type: "object",
        properties: {
          experiences: {
            type: "array",
            items: {},
          },
          educations: {
            type: "array",
            items: {},
          },
        },
      },
      startup: {
        type: "object",
        properties: {
          logo: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              prefix: {
                type: "string",
              },
              filename: {
                type: "string",
              },
              mimeType: {
                type: "string",
              },
              filesize: {
                type: "number",
              },
              width: {
                type: "number",
              },
              height: {
                type: "number",
              },
              sizes: {
                type: "object",
                properties: {
                  thumbnail: {
                    type: "object",
                    properties: {
                      width: {},
                      height: {},
                      mimeType: {},
                      filesize: {},
                      filename: {},
                      url: {},
                    },
                  },
                },
                required: ["thumbnail"],
              },
              createdAt: {
                type: "string",
              },
              updatedAt: {
                type: "string",
              },
              url: {
                type: "string",
              },
            },
          },
          address: {
            type: "string",
          },
          website: {
            type: "string",
          },
          socials: {
            type: "array",
            items: {
              type: "object",
              properties: {
                platform: {
                  type: "string",
                },
                url: {
                  type: "string",
                },
                id: {
                  type: "string",
                },
              },
            },
          },
          bio: {
            type: "string",
          },
          introduction: {
            type: "string",
          },
          pitchDeckLink: {
            type: "string",
          },
          isHidePitchDeckLink: {
            type: "boolean",
          },
          foundingYear: {
            type: "string",
          },
          challenge: {
            type: "string",
          },
          stage: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              label: {
                type: "string",
              },
              type: {
                type: "string",
              },
              _deleted: {
                type: "boolean",
              },
              createdAt: {
                type: "string",
              },
              updatedAt: {
                type: "string",
              },
            },
          },
          businessType: {
            type: "string",
          },
          teamSize: {
            type: "string",
          },
          lookingFor: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
                type: {
                  type: "string",
                },
                _deleted: {
                  type: "boolean",
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
              },
            },
          },
          industries: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
                type: {
                  type: "string",
                },
                _deleted: {
                  type: "boolean",
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
              },
            },
          },
          subIndustries: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
                type: {
                  type: "string",
                },
                _deleted: {
                  type: "boolean",
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
              },
            },
          },
          techs: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
                type: {
                  type: "string",
                },
                _deleted: {
                  type: "boolean",
                },
                createdAt: {
                  type: "string",
                },
                updatedAt: {
                  type: "string",
                },
              },
            },
          },
          categories: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              label: {
                type: "string",
              },
              type: {
                type: "string",
              },
              _deleted: {
                type: "boolean",
              },
              createdAt: {
                type: "string",
              },
              updatedAt: {
                type: "string",
              },
            },
          },
        },
      },
      mentorMentoringProgrammeTopicMinutes: {
        type: "array",
        items: {},
      },
      description: {
        type: "string",
      },
      calendly: {
        type: "object",
        properties: {
          url: {
            type: "string",
          },
          authCode: {
            type: "string",
          },
          token: {
            type: "object",
            properties: {
              access_token: {
                type: "string",
              },
              token_type: {
                type: "string",
              },
              expires_in: {
                type: "number",
              },
              refresh_token: {
                type: "string",
              },
              scope: {
                type: "string",
              },
              created_at: {
                type: "number",
              },
              owner: {
                type: "string",
              },
              organization: {
                type: "string",
              },
            },
          },
          user: {
            type: "object",
            properties: {
              avatar_url: {},
              created_at: {
                type: "string",
              },
              current_organization: {
                type: "string",
              },
              email: {
                type: "string",
              },
              name: {
                type: "string",
              },
              resource_type: {
                type: "string",
              },
              scheduling_url: {
                type: "string",
              },
              slug: {
                type: "string",
              },
              timezone: {
                type: "string",
              },
              updated_at: {
                type: "string",
              },
              uri: {
                type: "string",
              },
            },
          },
          event: {
            type: "object",
            properties: {
              active: {
                type: "boolean",
              },
              admin_managed: {
                type: "boolean",
              },
              booking_method: {
                type: "string",
              },
              color: {
                type: "string",
              },
              created_at: {
                type: "string",
              },
              custom_questions: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    answer_choices: {
                      type: "array",
                      items: {},
                    },
                    enabled: {
                      type: "boolean",
                    },
                    include_other: {
                      type: "boolean",
                    },
                    name: {
                      type: "string",
                    },
                    position: {
                      type: "number",
                    },
                    required: {
                      type: "boolean",
                    },
                    type: {
                      type: "string",
                    },
                  },
                },
              },
              deleted_at: {},
              description_html: {},
              description_plain: {},
              duration: {
                type: "number",
              },
              internal_note: {},
              kind: {
                type: "string",
              },
              locations: {},
              name: {
                type: "string",
              },
              pooling_type: {},
              position: {
                type: "number",
              },
              profile: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                  owner: {
                    type: "string",
                  },
                  type: {
                    type: "string",
                  },
                },
              },
              scheduling_url: {
                type: "string",
              },
              secret: {
                type: "boolean",
              },
              slug: {
                type: "string",
              },
              type: {
                type: "string",
              },
              updated_at: {
                type: "string",
              },
              uri: {
                type: "string",
              },
            },
          },
        },
      },
      loginAttempts: {
        type: "number",
      },
    },
    required: ["id"],
  };
