import { authStore } from "../../utilities/authStore";
import { chatsStore } from "../../utilities/stores/chats/chatsStore";
import { generateUsersAndFounders } from "../../utilities/stores/chats/chatsStore.tools";
import { resetScrollPositions } from "./utils/helpers";

export function handleGetAndAddNewMessage(messageText, chatId) {
  const thread = chatsStore.threads.items.value.find(
    (thread) => thread.id === chatId
  );
  const lastMessageId =
    chatsStore.threads.itemsMessages.value[chatId].at(-1)?.id || 0;
  const nextLocalMessageId = (Number(lastMessageId) + 0.1).toFixed(1);
  const participantInfo = {};

  /* 
    If the user is not a participant in the thread, we should add the user as a participant.
    This is only necessary if the thread is not just created which is indicated by the toConfirm property.
    When the thread is created the user who created the thread is automatically added as a participant.
  */
  if (!thread.toConfirm) {
    const isThisUserAParticipant = checkIfUserIsAParticipantInThread(
      thread.participants
    );

    if (!isThisUserAParticipant) {
      participantInfo.newParticipant = {
        userId: authStore.userId.value,
        founderId: authStore.founderId.value,
      };
    }
  }

  const finalId = authStore.founderId.value || authStore.userId.value;
  const commonProps = {
    chatId: thread.id,
    text: messageText,
    userId: finalId,
    localId: nextLocalMessageId,
    programmeId: authStore.programmeId.value,
  };

  const usersMap = generateUsersAndFounders();
  const selfParticipant = usersMap.get(finalId);

  const messagePayload = {
    ...commonProps,
    ...participantInfo,
    cohortId: authStore.cohort.value[0]?.id,
    meta: {
      displayName: selfParticipant?.displayName,
      imageUrl: selfParticipant?.imageUrl,
    },
  };

  const localMessageData = {
    ...commonProps,
    id: nextLocalMessageId,
    time: `${Date.now()}`,
    toConfirm: true,
  };

  chatsStore.threads.replaceThreadMessages(chatId, [
    ...(chatsStore.threads.itemsMessages.value[chatId] || []),
    localMessageData,
  ]);

  return {
    messagePayload,
    localMessageData,
  };

  function checkIfUserIsAParticipantInThread(participants = []) {
    return participants.some(
      ({ user, founder }) =>
        (!founder?.id || founder.id === authStore.founderId.value) &&
        user?.id === authStore.userId.value
    );
  }
}

export function sendMessageToAnalytics(messageToSendToAnalytics) {
  posthog?.capture("threads-message-created", {
    id: messageToSendToAnalytics.id,
    text: messageToSendToAnalytics.text,
    chatId: messageToSendToAnalytics.chatId,
    userId: messageToSendToAnalytics.userId,
  });
}

export function sendThreadToAnalytics(threadToSentToAnalytics) {
  posthog?.capture("threads-thread-created", {
    id: threadToSentToAnalytics.id,
    title: threadToSentToAnalytics.title,
    userId: threadToSentToAnalytics.userId,
  });
}

export function checkIfScrollPositionsShouldBeReset() {
  /* 
    - If user navigates to another threads page, the scroll position should be kept.
    - If the navigation occurs to other pages, the scroll position should be reset.
  */

  if (!location.pathname.startsWith("/threads")) {
    resetScrollPositions();
    chatsStore.threads.minimizedThreads.value = [];
  }
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function checkOldRouteAndRedirect() {
  if (
    location.pathname.startsWith("/threads") &&
    location.pathname.includes(".html")
  ) {
    const searchParams = new URLSearchParams(location.search);
    const threadId = searchParams.get("id") || "";
    const categoryId = searchParams.get("categoryId");
    const params = categoryId ? `?categoryId=${categoryId}` : "";

    window.location.href = `/threads/${threadId}${params}`;
  }
}
