import { createRxDatabase, addRxPlugin } from "rxdb";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { wrappedValidateAjvStorage } from "rxdb/plugins/validate-ajv";
import { getRxStorageIndexedDB } from "rxdb-premium/plugins/storage-indexeddb";
import { startupsSchema } from "./schemas";
import { startupsReplicateHandler } from "./replications/startupsReplicateHandler";

if (process.env.NODE_ENV === "development") {
  addRxPlugin(RxDBDevModePlugin);
}

export const RXDB_DATABASE_NAME = "mydatabase";

const instance = createDb();
export const replicationStates = {
  startup: null,
};

function wrappedValidateSchema(options) {
  if (process.env.NODE_ENV === "development") {
    return wrappedValidateAjvStorage(options);
  }

  return options.storage;
}

async function createDb() {
  const storage = wrappedValidateSchema({
    storage: getRxStorageIndexedDB(),
  });

  const myDatabase = await createRxDatabase({
    name: RXDB_DATABASE_NAME,
    storage: storage,
  });

  console.log("DB CREATED!?", myDatabase);

  await myDatabase.addCollections({
    startups: {
      schema: startupsSchema,
    },
  });

  replicationStates.startup = startupsReplicateHandler(myDatabase);

  return myDatabase;
}

export function getDatabase() {
  console.log("INSTANCE", instance._instance);
  return instance;
}
